<template>
  <div class="d-flex flex-column gap-5">
    <b-card v-if="personalInformation.length">
      <GridReport
        :data="personalInformation"
        :statusOptions="statusOptions"
        :title="'Personal Information'"
      />
    </b-card>
    <b-card v-if="inquiries.length">
      <GridReport
        :data="inquiries"
        :statusOptions="statusOptions"
        :title="'Inquiries'"
      />
    </b-card>
    <b-card v-if="publicRecords.length">
      <GridReport
        :data="publicRecords"
        :statusOptions="statusOptions"
        :title="'Public Records'"
      />
    </b-card>
    <b-card v-if="accounts.length">
      <GridReport
        :data="accounts"
        :statusOptions="statusOptions"
        :title="'Accounts'"
        :showInfo="true"
      />
    </b-card>
    <b-card>
      <div class="d-flex justify-content-end">
        <b-button variant="danger" @click="cancel()" class="mr-2">
          Cancel
        </b-button>
        <b-button variant="success" @click="deleteData()">Next Step</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import GridReport from "@/views/commons/components/ncr/components/delete/GridReport.vue";
import ReportService from "@/views/commons/components/ncr/service/report.service";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
export default {
  components: {
    GridReport,
  },
  data() {
    return {
      personalInformation: [],
      inquiries: [],
      publicRecords: [],
      accounts: [],
      statusOptions: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      counterChangedItems: "RequestWorkPlanStore/G_CHANGED_REPORT_ITEMS",
    }),
  },
  created() {
    this.getReport();
    this.getStatusOptions();
  },
  methods: {
    ...mapMutations({
      M_RESET_CHANGED_REPORT_ITEMS:
        "RequestWorkPlanStore/M_RESET_CHANGED_REPORT_ITEMS",
    }),
    async getReport() {
      try {
        this.addPreloader();
        const params = {
          id_account: this.$route.params.accountId,
        };
        const { data } = await ReportService.getReportForEdit(params);
        this.personalInformation = data.personal_information;
        this.inquiries = data.inquiries;
        this.publicRecords = data.public_records;
        this.accounts = data.accounts;

        if (
          this.personalInformation.length == 0 &&
          this.inquiries.length == 0 &&
          this.publicRecords.length == 0 &&
          this.accounts.length == 0
        ) {
          await ReportService.changeStatus({
            id_account: this.$route.params.accountId,
            personal_information: [],
            inquiries: [],
            public_records: [],
            accounts: [],
            user_id: this.currentUser.user_id,
            id_request: this.$route.params.requestId,
          });

          this.goToReport();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },
    async getStatusOptions() {
      try {
        const { data } = await ClientsOptionsServices.getStatusLetters();
        this.statusOptions = data.filter((item) => [3, 4].includes(item.id));
      } catch (error) {
        console.log(error);
      }
    },
    async deleteData() {
      const confirm = await this.showConfirmSwal(
        "Are you sure you want to update the status of this report?"
      );
      if (!confirm.value) return;
      const checkedInfo = this.obtainChecked(this.personalInformation);
      const checkedInquiries = this.obtainChecked(this.inquiries);
      const checkedPublicRecords = this.obtainChecked(this.publicRecords);
      const checkedAccounts = this.obtainChecked(this.accounts);
      try {
        this.addPreloader();
        await ReportService.changeStatus({
          id_account: this.$route.params.accountId,
          personal_information: checkedInfo,
          inquiries: checkedInquiries,
          public_records: checkedPublicRecords,
          accounts: checkedAccounts,
          user_id: this.currentUser.user_id,
          id_request: this.$route.params.requestId,
        });
        this.goToReport();
        this.M_RESET_CHANGED_REPORT_ITEMS();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    obtainChecked(data) {
      return data
        .map((item) =>
          item.filter((subItem) => [3, 4].includes(subItem.status))
        )
        .flat()
        .map((item) => {
          return {
            id: item.id,
            status_id: item.status,
          };
        });
    },
    async cancel() {
      if (this.counterChangedItems > 0) {
        const { isConfirmed } = await this.showQuestionSwal(
          "Pending changes",
          "You've changed the status of some items in the report. Are you sure you want to cancel?"
        );

        if (isConfirmed) {
          this.M_RESET_CHANGED_REPORT_ITEMS();
          this.$router.go(-1); // redirects to the previous view (request workplan table)
        }
      } else {
        this.$router.go(-1);
      }
    },
    goToReport() {
      this.$router.push({
        name: "report-lead-specialists-ad-cr",
        params: {
          idlead: this.$route.params.idlead,
          idfile: this.$route.params.idfile,
          accountId: this.$route.params.accountId,
          idSection: this.$route.params.requestId,
          section: "workplan",
          idBureau: 0,
        },
      });
    },
  },
};
</script>
